// You can live edit this code below the import statements
import React from 'react';
import Slide from 'react-reveal/Slide';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Landing.scss';



class Slider extends React.Component {

  state ={
    redirect: false
  }
  
  constructor(props) {
    super(props);
    this.state = { show: true };
    this.handleClick = this.handleClick.bind(this);
    
  }
  handleClick() {
    this.setState({ show: !this.state.show });
    this.setState({ redirect: false });
  }

  componentWillUpdate(){
    setTimeout(() => { 
        this.props.history.push('/portfolio');
    }, 2700)
}
  
  
  render() {

    
  
    
    return (



      <div className="container-fluid">
     
      <div className="row">
        
        <Slide left when={this.state.show}>
          <div className="curtainright">
        <img src="https://derekwebdev.com/curtain-left.png" height="100%" alt="curtrainright" width="80%" className="float-left" /></div>
        </Slide>
      

 
        <Slide right when={this.state.show}>
          <div className="curtainleft">
        <img src="https://derekwebdev.com/curtain-left.png" alt="curtainleft" height="100%" width="80%" className="float-right"/></div>
        </Slide>
        </div>
     

<div className="portbtn">
  <button className="btn btn-outline-danger btn-lg" type="button"
          onClick={this.handleClick} >
          { this.state.show } Enter Portfolio
        </button>
        </div>
        <div className="front-logo">
        <img src="https://derekwebdev.com/images/derekwebdevlogo.png" alt="logo" height="18%" width="18%" className=""/></div>
    
      
        <h3 className="text-center title">DerekWebDev</h3>
  </div>
  
    );
  }
}



export default Slider;
