import React from 'react';

import './SideDrawer.scss';

const sideDrawer = props =>(
    



<nav className="side-drawer">
<a href="/"><img src="https://derekwebdev.com/images/derekwebdevlogo.png" alt="logo" height="70vh" width="55vh" className="rounded mx-auto d-block pt-3"/></a>
    <ul>
                    <li><a href="/projects">Projects</a></li>
                    <li><a href="/services">Services</a></li>
                    <li><a href="/contact">Contact</a></li>
                    <li><a href="/blog">Blog</a></li>
    </ul>
</nav>

);

export default sideDrawer;