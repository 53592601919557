import React, { Component } from 'react'
import '../Main/Main.scss';
import Navbar from '../Navbar/Navbar';
import SideDrawer from '../SideDrawer/SideDrawer';
import Slide from 'react-reveal/Slide';

export default class Contact extends Component {
  state={
    sideDrawerOpen: false
    
  };
  
  drawerToggleClickHandler = () => {
  this.setState((prevState) => {
    return {sideDrawerOpen: !prevState.sideDrawerOpen};
  });
  
  }; 
  
  
      render() {
        let sideDrawer;
        
        if (this.state.sideDrawerOpen){
          sideDrawer = <SideDrawer />
       
        }
          return (
           

<div className="page-bg">
  <Navbar/>
{sideDrawer}
<div style={{marginTop: '45px', marginBottom: '30px'}}>
        <Slide top>
        <h2 className="text-center mt-2 text-white">Contact</h2>
        
        </Slide>
      </div>
<div className="animation-wrapper">
  <div className="particle particle-1"></div>
  <div className="particle particle-2"></div>
  <div className="particle particle-3"></div>
  <div className="particle particle-4"></div>

  <div>
        <Slide left>

  <div className="container">
  <div className="row">
<form className="col con-form">
		<input className="name" placeholder="What is your name?" className="name" required />
		<input className="emailaddress" placeholder="What is your email?" className="email" type="email" required />
    <textarea rows="4" cols="50" className="subject" placeholder="Please enter your message" className="message" required></textarea>
  {/*   <input name="submit" class="btn" type="submit" value="Send" /> */}
</form>
</div>
</div>



</Slide>




      </div>
           
      <div>
        <Slide bottom>

      <div className="row social-icons2">
    
<a href="https://github.com/derekwebdevcom" rel="noopener noreferrer"  target="_blank"><img src="https://derekwebdev.com/images/github.png" alt="github" height="60vh" width="70vh" className="d-block pr-3"/></a>
<a href="https://codepen.io/derek-andrews/" rel="noopener noreferrer"  target="_blank"><img src="https://derekwebdev.com/images/codepen.png" alt="logo" height="60vh" width="63vh" className="d-block"/></a>
<a href="https://linkedin.com/in/derek-andrews-5280" rel="noopener noreferrer" target="_blank"><img src="https://derekwebdev.com/images/linkedin.png" alt="logo" height="60vh" width="65vh" className="d-block pl-3"/></a>


  
</div>

</Slide>
      </div>
                
            </div>

            </div>
        )
    }
}
