import React from 'react';

import '../SideDrawer/DrawerToggleButton';
import './Navbar.css';
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton';


const navbar = props =>(
    <header className="navbar">
        <nav className="navbar_navigation">
            <div>
                <DrawerToggleButton click={props.drawerClickHandler}  />
            </div>
            <div className="navbar_logo"><a href="/">DerekWebDev</a> </div>
            <div className="spacer"></div>
            <div className="navbar_navigation-items">
                <ul>
                    <li><a href="/projects">Projects</a></li>
                    <li><a href="/services">Services</a></li>
                    <li><a href="/contact">Contact</a></li>
                    <li><a href="/blog">Blog</a></li>
                </ul>
            </div>
        </nav>
    </header>
);
export default navbar;