import React, { Component } from 'react'
import '../Main/Main.scss';


export default class Blog extends Component {
    render() {
        return (
           

<div className="page-bg">

<div className="animation-wrapper">
  <div className="particle particle-1"></div>
  <div className="particle particle-2"></div>
  <div className="particle particle-3"></div>
  <div className="particle particle-4"></div>
                
            </div>

            </div>
        )
    }
}
