import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Slider from "./components/Landing/Slider";
import Main from "./components/Main/Main";
import Contact from "./components/Contact/Contact";
import Services from "./components/Services/Services";
import Blog from "./components/Blog/Blog";



function App() {
  return (
    <Router>
    <div className="App">
    <Route exact path="/" component={Slider} />
     <Route exact path="/portfolio" component={Main} />
     <Route exact path="/contact" component={Contact} />
     <Route exact path="/blog" component={Blog} />

     <Route exact path="/services" component={Services} />
    




    </div>
    </Router>
  );
}

export default App;
